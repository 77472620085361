import axios from 'axios'
const request = {}
const headers = {}
var configUrl = 'https://api.gdboluo.com/'
request.globalRequest = (url, method, data, power) => {
	headers['content-type'] = 'application/json;charset=UTF-8';
	if (method == 'get') {
		return axios({
			method,
			url:  url,
			params: data,
			dataType: 'json',
			headers: headers
		}).then(res => {
			return res.data
		})
	} else {
		return axios({
			method,
			url:  url,
			data: data,
			dataType: 'json',
			headers: headers
		}).then(res => {
			return res.data
		})
	}
}
export default request
