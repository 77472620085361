<!-- 滚动数据 -->
<template>
	<dv-scroll-board :config="config"  />
</template>
<script>
	export default {
		data() {
			return {
				title:'采购商订单'
			};
		},
		mounted() {},
		props:['config'],
		methods: {},
	};
</script>
<style>
</style>
