<!-- 新型农业主体 -->
<template>
	<div class="price-quotation" ref="year_quotation" :style="{height:'100%',width:'100%'}"></div>
</template>
<script>
	// echarts3.0引入案例
	import * as echarts from 'echarts'
	export default {
		data() {
			return {};
		},
		props: [
			'lineData',
			'xData' // x轴
		],
		created() {},
		watch: {
			xData() {
				this.initChart2();
			}
		},
		mounted() {
			this.initChart2();
		},
		methods: {
			initChart2() {
				// 数据项
				var option = {
					title: {
						text: '涉农新型经营主体',
						left: 'center',
						// 标题字体颜色
						textStyle: {
							color: '#00F1FF',
							fontSize: 16,
						}
					},
					tooltip: {
						trigger: "axis",
						formatter: '{b}:{c}%',
						axisPointer: {
							type: "cross",
							label: {
								backgroundColor: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 1,
									y2: 1,
									colorStops: [{
											offset: 0,
											color: "#286CE9" // 0% 处的颜色
										},
										{
											offset: 1,
											color: "#01D1FF" // 100% 处的颜色
										}
									],
									globalCoord: false // 缺省为 false 
								}
								//鼠标停留提示背景色
							}
						}
					},
					legend: {
						show:false,
						right: 30,
						textStyle: {
							color: '#ffffff'
						},
						data: ["农业主体"]
					},
					grid: {
						left: "7%",
						right: "4%",
						bottom: "3%",
						containLabel: true,
						borderColor: "transparent",
						shadowColor: "blue",
						shadowBlur: 10,
						shadowOffsetX: 2,
						shadowOffsetX: 2,
						show: true
					},
					xAxis: [{
						name: this.$props.xTitle,
						// nameGap:20,//坐标轴名称与轴线之间的距离
						nameLocation: "start",
						type: "category",
						nameTextStyle: {
							//坐标轴名称的字体样式
							color: "#fff",
							fontStyle: '200'
						},
						nameGap: 20, //坐标轴名称与轴线之间的距离 
						axisLine: {
							//X轴（或Y轴）那条线的样式设置
							show: true,
							lineStyle: {
								color: "#fff",
								width: 5,
								width: 1,
								type: "solid",
								shadowColor: "blue",
								shadowBlur: 10,
								shadowOffsetX: 2,
								shadowOffsetX: 2
							}
						},
						minInterval: 1,
						axisPointer: {
							//鼠标移到折线点上的设置 
							type: "line",
							lineStyle: {
								color: "#04CDF4"
							},
							shadowStyle: {
								color: "#04CDF4"
							}
						},
						// x轴倾斜
						axisLabel: {
							interval: 0,
							formatter: function(value) {
								//x轴的文字改为竖版显示
								var str = value.split("");
								return str.join("\n");
							}
						},
						splitArea: {},
						splitLine: {},
						// boundaryGap: false,
						data: this.$props.xData
					}],
					//
					yAxis: [{
						splitLine: {
							//坐标轴间隔线
							show: true,
							lineStyle: {
								color: "#1C4E8A",
								shadowColor: "#1C4E8A",
								shadowBlur: 10,
								type: "solid"
							}
						},
						name: "(数量占比/%)",
						// nameGap:25,//坐标轴名称与轴线之间的距离
						type: "value",
						nameTextStyle: {
							//坐标轴名称的字体样式
							color: "#fff"
						},
						nameLocation: "end",

						axisLine: {
							lineStyle: {
								width: 0,
								color: "#fff",
								type: "solid",
								shadowColor: "#04CDF4",
								shadowBlur: 10,
								shadowOffsetX: 2,
								shadowOffsetX: 2
							}
						}
					}],
					series: [{
						name: "农业主体",
						type: "bar",
						barWidth: 15,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(
								0, 0, 0, 1,
								[{
										offset: 0,
										color: '#83bff6'
									},
									{
										offset: 0.5,
										color: '#188df0'
									},
									{
										offset: 1,
										color: '#188df0'
									}
								]
							)
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(
									0, 0, 0, 1,
									[{
											offset: 0,
											color: '#2378f7'
										},
										{
											offset: 0.7,
											color: '#2378f7'
										},
										{
											offset: 1,
											color: '#83bff6'
										}
									]
								)
							}
						},
						lineStyle: {
							width: 1,
							color: "#04CDF4",
							shadowColor: "#04CDF4",
							shadowBlur: 10,
							type: "solid"
						},
						label: {
							normal: {
								show: true,
								position: "top",
								formatter: '{c}%',
								color: '#ffffff',
								label: {
									textStyle: {
										fontSize: 12
									}
								}
							}
						},
						areaStyle: {
							normal: {}
						},
						data: this.$props.lineData
					}]
				};
				var myChart = this.$echarts.init(this.$refs.year_quotation);
				window.onresize = myChart.resize;
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			}
		}
	};
</script>
