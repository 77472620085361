<template>
	<div id="app">	
		<router-view  />
	</div>
</template>
<script>
	export default {
		data() {
			return {
			};
		},
		created() {
		
		}
	}
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
	}
	#app {
		width: 100%;
	}
</style>
