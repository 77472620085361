<!-- 农作物种类占比 -->
<template>
	<div :style="{height:'100%',width:'100%'}" ref="myEchart"></div>
</template>
<script>
	export default {
		name: "echarts",
		data() {
			return {
				chart: null
			};
		},
		props:['scaleData'],
		mounted() {	
			this.chinaConfigure();	
		},
		watch: {
			scaleData() {
				this.chinaConfigure();
			}
		},
		beforeDestroy() {
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
		},
		methods: {
			chinaConfigure() {
				// console.log(this.scaleData)
				this.chart = this.$echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置    
				window.onresize = this.chart.resize;		
				let rich = {
					white: {
						color: '#ddd',
						align: 'center',
						padding: [3, 0]
					}
				};
				let placeHolderStyle = {
					normal: {
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						color: 'rgba(0, 0, 0, 0)',
						borderColor: 'rgba(0, 0, 0, 0)',
						borderWidth: 0
					}
				};
				let data = [];
				
				let color = ['#00ffff', '#00cfff', '#006ced', '#ffe000',
				 '#ffa800', '#ff5b00', '#ff3000','#8abd50', '#9db2ff', '#ff85fd',
				 '#00acac', '#008faf', '#004a9e', '#937f00',
				  '#cc8500', '#b03d00', '#b12000','#87b84e', '#6977ab', '#a656a6']
				for (let i = 0; i < this.scaleData.length; i++) {
					
					data.push({
						value: this.scaleData[i].value,
						name: this.scaleData[i].name,
						itemStyle: {
							normal: {
								borderWidth: 6,
								shadowBlur: 10,
								borderColor: color[i],
								shadowColor: color[i]
							}
						}
					}, {
						value: 2,
						name: '',
						itemStyle: placeHolderStyle
					});
				}
			
				let option = {
					title: {
						left: 'center',
						text: '主要农产品种类占比',
						textStyle: {
							color: '#00F1FF',
							fontSize: 16,
						}
					},
					series: [{
						name: '',
						type: 'pie',
						clockWise: false,
						radius: ['66%', '68%'],
						center: ['50%', '50%'],
						hoverAnimation: false,
						


						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'outside',
									color: '#ddd',
									formatter: (params) => {
								
										if (params.name !== '') {
											return params.name + '\n{white|' + '占比' + params.value + '%}';
										} else {
											return '';
										}
									},
									rich: rich
								},
								labelLine: {
									length: 10,
									length2: 30,
									show: true,
									color:  color
								}
							}
						},
						data: data
					}]
				}
				this.chart.setOption(option)
			}
		}
	}
</script>
