<!-- 天气 -->
<template>
	<div class="weater" v-if="weatherInfo.length != 0">
		<div class="weatherTitle">{{weatherInfo[0].date.split('-')[1]+'月'+weatherInfo[0].date.split('-')[2]+'日'}}
			{{weatherInfo[0].week}} </div>
		<div class="left">
			<img :src="windImg7" alt="" v-if="weatherInfo[0].wea_img === 'xue'" />
			<img :src="windImg2" alt="" v-else-if="weatherInfo[0].wea_img === 'lei'" />
			<img :src="windImg9" alt="" v-else-if="weatherInfo[0].wea_img === 'shachen'" />
			<img :src="windImg8" alt="" v-else-if="weatherInfo[0].wea_img === 'bingbao'" />
			<img :src="windImg6" alt="" v-else-if="weatherInfo[0].wea_img === 'wu'" />
			<img :src="windImg3" alt="" v-else-if="weatherInfo[0].wea_img === 'yun'" />
			<img :src="windImg5" alt="" v-else-if="weatherInfo[0].wea_img === 'yu'" />
			<img :src="windImg6" alt="" v-else-if="weatherInfo[0].wea_img === 'yin'" />
			<img :src="windImg1" alt="" v-else-if="weatherInfo[0].wea_img === 'qing'" />
			<!-- 实时温度 -->
			<div class="smallItem temp">
				{{weatherInfo[0].tem}}<span>(实时)</span>
			</div>
			<div class="smallItem">
				{{weatherInfo[0].tem2 +'~'+ weatherInfo[0].tem1}}
			</div>
			<div class="smallItem">
				风速{{weatherInfo[0].win_speed.slice(0,4)}}
			</div>
			<div class="smallItem">
				{{weatherInfo[0].win}}
			</div>
			<!-- 空气质量 -->
			<div class="smallItem tipInfo">
				{{weatherInfo[0].air_level }}
			</div>
		</div>
		<div class="right">
			<div class="item" v-for="(item,index) of weatherInfo.slice(1,5)">
				<div class="smallItem"> {{item.week}}</div>
				<div class="smallItem">{{item.date.split('-')[1]+'月'+item.date.split('-')[2]+'日'}}</div>
				<img :src="windImg7" alt="" v-if="item.wea_img === 'xue'" />
				<img :src="windImg2" alt="" v-else-if="item.wea_img === 'lei'" />
				<img :src="windImg9" alt="" v-else-if="item.wea_img === 'shachen'" />
				<img :src="windImg8" alt="" v-else-if="item.wea_img === 'bingbao'" />
				<img :src="windImg6" alt="" v-else-if="item.wea_img === 'wu'" />
				<img :src="windImg3" alt="" v-else-if="item.wea_img === 'yun'" />
				<img :src="windImg5" alt="" v-else-if="item.wea_img === 'yu'" />
				<img :src="windImg6" alt="" v-else-if="item.wea_img === 'yin'" />
				<img :src="windImg1" alt="" v-else-if="item.wea_img === 'qing'" />
				<div class="smallItem">
					{{item.tem2 +'~'+ item.tem1}}
				</div>
				<div class="smallItem">
					风速{{item.win_speed.slice(0,4)}}
				</div>
				<div class="smallItem">
					{{item.win}}
				</div>
				<div class="smallItem tipInfo">
					{{item.air_level }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Weather",
		data() {
			return {
				windImg1: require('../static/img/1.png'),
				windImg2: require('../static/img/2.png'),
				windImg3: require('../static/img/3.png'),
				windImg5: require('../static/img/5.png'),
				windImg6: require('../static/img/6.png'),
				windImg7: require('../static/img/7.png'),
				windImg8: require('../static/img/9.png'),
				windImg9: require('../static/img/8.png')
			}
		},
		props: ['weatherInfo']
	}
</script>
<style lang="scss" scoped>
	// 未来四天天气
	.weater {

		width: 100%;
		height: 100%;
		display: flex;
		// align-items: center;
		font-size: 14px;
		position: relative;
		.weatherTitle {
			position: absolute;
			left: 20px;
			top: -30px;
		}
		// 左侧天气(当天天气)
		.left {
			flex: 0.8;
			display: flex;
			flex-direction: column;
			align-items: center;
			// 实时温度
			.temp {
				font-size: 24px;
				font-weight: bold;

				span {
					font-size: 16px;
					font-weight: normal;
				}
			}
			img {
				width: 60px;
				height: 60px;
			}
		}

		// 右侧天气(未来四天天气)
		.right {
			flex: 2;
			display: flex;
			.item {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				border-left: 1px solid rgb(0 120 255 / 20%);
				img {
					width: 40px;
					height: 40px;
				}
			}

		}
		.smallItem {
			margin-bottom: 10px;
		}
		img {
			margin-bottom: 10px;
		}
		.tipInfo {
			padding: 2px 6px;
			background-color: rgb(0 190 255 / 77%);
			color: #fff;
			border-radius: 4px;
		}
	}
</style>
