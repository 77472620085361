<!-- 地图 -->
<template>
	<div style="width:75%;height:90%;position: relative;top: 5%;left: 1%;" class="mapBg"></div>
</template>
<script>
	export default {
		data() {
			return {
			}
		},
		methods: {}
	}
</script>
<style>
.mapBg{
	background: url(../static/img/map1.png) no-repeat center center;
	background-size: 100% 100%;
}
</style>
