import request from '@/common/request.js'
const api = {}
// 天气
api.getWether = params => request.globalRequest('township/weather/now', 'get', params)
// 南山镇全景图
api.townshipData = params => request.globalRequest('township/detail/num', 'get', params)
// 种类
api.townshipLand = params => request.globalRequest('township/land/list/all', 'get', params)
// 环形图
api.townshipDataHuan = params => request.globalRequest('township/type/count', 'get', params)
// 柱形图
api.townshipAgriculture = params => request.globalRequest('township/newAgriculture/count', 'get', params)
// 农业金融
api.townshipFinance = params => request.globalRequest('township/finance/list/nan', 'get', params)
 // 全国市场采购
api.getAgricultureSupplyData = params => request.globalRequest('township/agriculturePurchase/list/all', 'get', params)
//  全镇供应信息 
api.getFruitData = params => request.globalRequest('township/agricultureSupply/list/all  ', 'get', params)
//  折线图
api.marketPriceData = params => request.globalRequest('marketPrice/count', 'get', params)
export default api
