<!-- 农作物种类 -->
<template>
	<div :style="{height:'100%',width:'100%'}" ref="myEchart"></div>
</template>
<script>
	export default {
		name: "echarts",
		data() {
			return {
				chart: null
			};
		},
		mounted() {
			this.chinaConfigure();
		},
		props:['yData','xData','dateData'],
		beforeDestroy() {
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
		},
		watch: {
			xData() {
				this.chinaConfigure();
			}
		},
		methods: {
			chinaConfigure() {
				let myChart = this.$echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置    
				window.onresize = myChart.resize;
				let option = {
					title: {
						left:20,
						text: '农产品产地价格曲线图',
				
						// 标题字体颜色
						textStyle: {
							color: '#00F1FF',
							fontSize: 16,
						}
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						right:30,
						// 每一项字体颜色
						textStyle: {
							color: '#fff'
						},
						// top:25,
						data:this.xData
					},

					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					// 下载图表按钮
					// toolbox: {
					// 	feature: {
					// 		saveAsImage: {}
					// 	}
					// },
					xAxis: {
						// x轴坐标轴的颜色
						axisLine: {
							lineStyle: {
								color: '#fff'
							}
						},
						type: 'category',
						boundaryGap: false,
						data: this.dateData
					},
					yAxis: {
						name: "(单位/元)",
						type: 'value',
						//设置网格线颜色 横方向
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#315070'],
								width: 1,
								type: 'solid'
							}
						},
						type: 'value',
						// y轴坐标轴的颜色
						axisLine: {
							lineStyle: {
								color: '#fff'
							}
						}
					},
					series:this.yData
				};
				myChart.setOption(option)
			}
		}
	}
</script>
