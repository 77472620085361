import Vue from 'vue'
import Pie from './pie.vue'
import LineChart from './lineChart.vue'
import ScrollInfo from './scrollInfo.vue'
import MapLine from './mapLine.vue'
import Wether from './wether.vue'
import BarLine from './barLine.vue'
const noDataLists = {
	install: function(Vue) {
		// 环形图
		Vue.component('Pie', Pie)
		// 折线图
		Vue.component('LineChart', LineChart)
		// 滚动数据
		Vue.component('ScrollInfo', ScrollInfo)
		// 地图
		Vue.component('MapLine', MapLine)
		// 天气
		Vue.component('Wether', Wether)
		// 柱状图
		Vue.component('BarLine', BarLine)
	}
}
export default noDataLists
