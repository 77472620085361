import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
Vue.use(VueRouter)
// 路由
const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/home.vue'),	
	}
]
const router = new VueRouter({
		routes: routes,
		mode: 'history',
		// 跳转时页面从顶部
		scrollBehavior (to, from, savedPosition) { 
		  return { x: 0, y: 0 }
		}
	})
export default router




  