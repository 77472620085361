import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 适配
// import 'lib-flexible';
// Vue 大屏数据展示组件库
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
// echarts5.0.0引入方式
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 组件
import noDataLists from '@/components/index'
Vue.use(noDataLists)
// 20210601  api详细
import api from './api/index'
Vue.prototype.$api = api
// 引入axios
import axios from 'axios'
// 配置跨域
axios.defaults.baseURL = '/api/'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
